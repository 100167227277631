<template>
	<div class="dialog">
		<el-dialog :title="typeFlag=='add'?'添加':'修改'" :visible.sync="dialogVisible" width="700px" :before-close="close">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="一级分类">
					<el-select size="small" v-model="ruleForm.parent_id">
						<el-option
                            v-for="item in catesList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分类名称" prop="name">
					<el-input size="small" v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item v-if="!ruleForm.parent_id" label="分类背景照片">
					<Qiniu :isCropper="true" :width="525" :height="420" :imgKey="ruleForm.cover" @uploadSuccess="uploadSuccess"></Qiniu>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close()">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	import { allCate } from '@/api/base'
	import { saveCate } from '@/api/classTag.js';
	import Qiniu from '@/components/qiniu.vue'
	export default {
		name: "ClassAdd",
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			typeFlag: { // 判断是新建 编辑 详情
				type: String,
				default: 'add'
			},
			row: { // 判断是新建 编辑 详情
				type: Object,
				default: ()=>{
					return {}
				}
			},
		},
		data() {
			return {
				catesList: [],
				ruleForm: {
					parent_id: '',
					name: '',
					cover: '',
				},
				rules: {
					name: [
						{ required: true, message: '请输入分类名称', trigger: 'blur' }
					],
				}
			}
		},
		components: {
			Qiniu
		},
		watch: {
			dialogVisible: {
				handler() {
					this.ruleForm.parent_id = this.row.parent_id || ''
					this.ruleForm.name = this.row.name || ''
					this.ruleForm.cover = this.row.cover || ''
				},
				immediate: true
			}
		},
		methods: {
			// 获取分类
			getallCate() {
				if(this.catesList.length > 0) return
				allCate({ action: 'save' }).then((res) => {
					this.catesList = res.data
				}).catch((err) => {
					console.log(err)
				})
			},
            // 确定
            submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					}
				});
			},
			save(){
				let body = this.ruleForm
				this.typeFlag == 'edit' ? body.id = this.row.id : ''
				saveCate(body).then((res) => {
					if(res.code == 200){
						this.close()
						this.$emit('getList');
					}
				}).catch((err) => {
					console.log(err, 222)
				})
			},
            // 关闭
			close() {
				this.ruleForm = {
					parent_id: '',
					name: '',
					cover: ''
				}
				this.$emit('close');
			},
			// 选择图片成功
			uploadSuccess(e) {
				this.ruleForm.cover = this.$constant.qnUrl + '/' + e.key
			},
		}
	};
</script>
