<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
            <el-button size="small" type="primary" @click="add()">添加</el-button>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="id" label="分类id"></el-table-column>
			<el-table-column prop="name" label="分类名称"></el-table-column>
			<el-table-column prop="parent_name" label="父级分类名称">
				<template slot-scope="scope">
					{{ scope.row.parent_name || '--' }}
				</template>
			</el-table-column>
			<el-table-column prop="cover" label="分类背景照片">
				<template slot-scope="scope">
					<img :src="scope.row.cover" style="width: 100px"/>
				</template>
			</el-table-column>
            <el-table-column label="操作">
				<template slot-scope="scope">
					<el-link type="primary" @click="edit(scope.row)" style="margin-right: 10px;">修改</el-link>
					<el-link type="primary" @click="deleteClass(scope.row.id)">删除</el-link>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
        <classAdd ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :row="row" @getList="getList" @close="close"/>
	</div>
</template>
<script>
    import classAdd from './component/classAdd.vue'
	import { cateList, delCate } from '@/api/classTag.js';
	export default {
		name: "Classification",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				// 列表
				total: 0,
				limit: 20,
				currentPage: 1,
				list: [],
                // 弹框
                typeFlag: 'add',
				dialogVisible: false,
				row: {}
			}
		},
		components: {
			classAdd
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 添加
			add() {
				this.$refs.add.getallCate()
				this.typeFlag = 'add'
				this.row = {}
				this.dialogVisible = true
            },
            // 修改
			edit(row) {
				this.$refs.add.getallCate()
                this.typeFlag = 'edit'
				this.row = row
				this.dialogVisible = true
			},
			// 删除
			deleteClass(id) {
				this.$confirm('确定删除吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delCate({ id }).then((res) => {
						if(res.code == 200) {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.currentPage = 1
							this.getList()
						}
					}).catch((err) => {
						console.log(err, 222)
					})
				})
				.catch(() => {});
			},
			// 获取列表
			getList() {
				this.loading = true
				cateList({
					limit: this.limit,
					page: this.currentPage,
				}).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false
				}).catch((err) => {
					this.loading = false
					console.log(err, 222)
				})
			},
			// 页码修改
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
            },
            // 关闭弹框
            close() {
                this.dialogVisible = false
            },
		}
	};
</script>